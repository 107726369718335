<template>
  <div>
    <div v-if="!selectedCategory">
      <h3 class="text-sm leading-6 font-normal text-gray-600">
        {{ $t("workflow.editor.conditionAddTitle") }}
      </h3>
      <SelectOptions
        :options="conditionOptions"
        :on-select="
          (value) => {
            selectedCategory = value;
          }
        "
      />
    </div>
    <div v-if="['deviation', 'amountDeviation'].includes(selectedCategory)">
      <div class="sm:col-span-3 mt-10">
        <label class="block text-sm font-medium text-gray-700 my-2">
          {{ $t("workflow.editor.deviationConditionTitle") }}
        </label>
        <Multiselect
          id="deviation-relation"
          v-model="deviation.relation"
          mode="single"
          :searchable="true"
          :options="amountRelationTypes"
          :placeholder="$t('workflow.editor.chooseRelation')"
          :value-prop="'value'"
        />
        <SwitchCheckbox
          v-model="deviation.absoluteDeviation"
          class="my-4 inline-flex"
          :label="$t('workflow.editor.absoluteDeviation')"
        />
        <div class="mt-4">
          <label for="price" class="block text-sm font-medium text-gray-700">{{
            deviationText
          }}</label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <div
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <span
                v-if="!deviation.absoluteDeviation"
                class="text-gray-500 sm:text-sm"
              >
                %
              </span>
            </div>
            <input
              id="price"
              type="number"
              min="0"
              :max="deviation.absoluteDeviation ? 999999999 : 100"
              name="id"
              :value="deviation.value"
              class="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              @keyup="onAttributeChangeDeviation"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedCategory == 'project'">
      <div class="pb-3">
        {{ $t("workflow.editor.projectDescription") }}
      </div>
      <div class="py-4">
        <SwitchCheckbox
          v-model="isProject"
          state-icons
          :label="$t('workflow.editor.isProject')"
        />
      </div>
      <ProjectSelect
        v-if="!isProject"
        v-model="project"
        mode="tags"
        :object="true"
        track-by="name"
        :create-option="false"
        :placeholder="
          $t('purchaseRequest.requestedFor.selectProjectMultiselect')
        "
      />
    </div>
    <div v-if="selectedCategory == 'amount'" class="flex flex-col gap-y-6">
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium text-gray-700 my-2">
          {{ $t("workflow.editor.amountConditionTitle") }}
        </label>
        <Multiselect
          id="amount-relation"
          v-model="amount.relation"
          mode="single"
          :searchable="true"
          :options="amountRelationTypes"
          :placeholder="$t('workflow.editor.chooseRelation')"
          :value-prop="'value'"
        />
      </div>
      <BetweenCurrencyInput v-if="isBetweenRelationSelected" />
      <CurrencyInput
        v-else
        id="conditions-currency-input"
        v-model.lazy="amount.value"
        placeholder="0"
        :label="$t('workflow.editor.amount')"
      />
    </div>
    <div v-if="selectedCategory == 'department'">
      <div class="sm:col-span-3 mt-10">
        <DepartmentSelect
          v-model="department"
          class="mt-6"
          :mode="'tags'"
          :label="'name'"
          :object="true"
          :placeholder="$t('workflow.editor.chooseDepartment')"
        />
      </div>
    </div>
    <div v-if="selectedCategory == 'category'">
      <div class="sm:col-span-3 mt-10">
        <CategorySelect v-model="category" :multiple="true" :object="true" />
      </div>
    </div>
    <div v-if="selectedCategory == 'user'">
      <div class="sm:col-span-3 mt-10">
        <Multiselect
          v-model="user"
          mode="tags"
          :searchable="true"
          :options="users"
          :placeholder="$t('workflow.editor.chosseUsers')"
          :label="'firstName'"
          :value-prop="'id'"
          :object="true"
          class="mt-6"
        />
      </div>
    </div>
    <div v-if="selectedCategory == 'supplier'">
      <div v-if="!unlistedSupplier" class="sm:col-span-3 mt-10">
        <SupplierSelect
          v-model="supplier"
          mode="tags"
          :placeholder="$t('workflow.editor.chooseSupplier')"
          :label="'name'"
          :object="true"
          class="mt-6"
        />
      </div>
      <div>
        <SwitchCheckbox
          v-model="unlistedSupplier"
          class="my-8 inline-flex"
          :label="$t('workflow.editor.unlistedSupplier')"
        />
      </div>
    </div>
    <div v-if="selectedCategory == 'legalEntity'">
      <div class="sm:col-span-3 mt-10">
        <Multiselect
          v-model="legalEntity"
          mode="tags"
          :searchable="true"
          :options="legalTendencies"
          :placeholder="$t('workflow.editor.chooseLegalTendency')"
          :label="'name'"
          :value-prop="'id'"
          :object="true"
          class="mt-6"
        />
      </div>
    </div>
    <div v-if="selectedCategory == 'budgetOwnerOfDepartment'">
      <div class="workflowItem text-center">
        {{ $t("workflow.editor.budgetOwnerOfDepartmentConditionLabel") }}
      </div>
    </div>
    <div v-if="selectedCategory == 'approverOfDepartment'">
      <div class="workflowItem text-center">
        {{ $t("workflow.editor.approverOfDepartmentConditionLabel") }}
      </div>
    </div>
    <div v-if="selectedCategory == 'requestType'">
      <div class="sm:col-span-3 mt-10">
        <Multiselect
          v-model="requestType"
          :mode="'single'"
          :searchable="true"
          :options="requestTypeOptions($t)"
          :placeholder="$t('workflow.editor.requestTypeConditionLabel')"
          class="mt-6"
        />
      </div>
    </div>
    <EditorButtons
      :reset="
        () => {
          selectedCategory = null;
        }
      "
      :save="onSave"
      :cancel="closeModal"
      :show-reset="selectedCategory != null"
      :show-save="isValidSubmission"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const workflowJson = require("@/assets/data/workflow.json");
const { conditionTypes, amountRelationTypes, conditionTypesInvoice } =
  workflowJson;
import { workflowMixin } from "@/components/mixins/index.js";
import {
  BetweenCurrencyInput,
  CategorySelect,
  CurrencyInput,
  SwitchCheckbox,
  SupplierSelect,
} from "@/components/shared/index";
import { isEmptyString } from "@/utils/utility_methods";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import DepartmentSelect from "@/components/shared/DepartmentSelect.vue";
import ProjectSelect from "@/components/shared/ProjectSelect.vue";
import { requestTypeOptions } from "@/utils/helpers/workflowHelper.js";

export default {
  components: {
    SupplierSelect,
    DepartmentSelect,
    SwitchCheckbox,
    CurrencyInput,
    BetweenCurrencyInput,
    CategorySelect,
    ProjectSelect,
  },
  mixins: [workflowMixin],
  data() {
    return {
      amountRelationTypes,
      selectedCategory: this.selectedOption,
      amount: {},
      category: [],
      user: [],
      department: [],
      deviation: {},
      legalEntity: [],
      supplier: [],
      unlistedSupplier: false,
      project: [],
      isProject: false,
      budgetOwnerOfDepartment: false,
      approverOfDepartment: false,
      requestType: "",
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "companySuppliersList",
      "supplierName",
    ]),
    deviationText() {
      return this.$t(
        `workflow.editor.${
          !this.deviation.absoluteDeviation
            ? "percentageDeviation"
            : "absDeviation"
        }`
      );
    },
    deviationCategorySelected() {
      return ["deviation", "amountDeviation"].includes(this.selectedCategory);
    },
    isValidSubmission() {
      if (this.selectedCategory == "amount") {
        const { relation, value, minValue, maxValue } = this.amount;
        return (
          (relation != null && value > 0) ||
          (relation === "BW" && this.validateBetweenValues(minValue, maxValue))
        );
      } else if (this.selectedCategory == "category") {
        return this.category.length > 0;
      } else if (this.selectedCategory == "user") {
        return this.user.length > 0;
      } else if (this.selectedCategory == "department") {
        return this.department.length > 0;
      } else if (this.deviationCategorySelected) {
        return this.deviation.relation && this.deviation.value > 0;
      } else if (this.selectedCategory == "legalEntity") {
        return this.legalEntity.length;
      } else if (this.selectedCategory == "supplier") {
        return this.supplier.length || this.unlistedSupplier;
      } else if (this.selectedCategory == "project") {
        return this.isProject || this.project.length;
      } else if (
        ["budgetOwnerOfDepartment", "approverOfDepartment"].includes(
          this.selectedCategory
        )
      ) {
        return true;
      } else if (this.selectedCategory == "requestType") {
        return !!this.requestType;
      }
      return false;
    },
    conditionValue() {
      if (this.selectedCategory == "amount") {
        return this.amount;
      } else if (this.selectedCategory == "category") {
        return this.category;
      } else if (this.selectedCategory == "user") {
        return this.user;
      } else if (this.selectedCategory == "department") {
        return this.department;
      } else if (this.deviationCategorySelected) {
        return this.deviation;
      } else if (this.selectedCategory == "legalEntity") {
        return this.legalEntity;
      } else if (this.selectedCategory == "supplier") {
        return this.supplier.length && !this.unlistedSupplier
          ? this.supplier
          : this.unlistedSupplier;
      } else if (this.selectedCategory == "project") {
        if (this.isProject) {
          return this.isProject;
        } else {
          return this.project;
        }
      } else if (
        ["budgetOwnerOfDepartment", "approverOfDepartment"].includes(
          this.selectedCategory
        )
      ) {
        return true;
      } else if (this.selectedCategory == "requestType") {
        return this.requestType;
      }

      return null;
    },
    isBetweenRelationSelected() {
      return this.amount.relation === "BW";
    },
    conditionOptions() {
      let options = conditionTypes;
      if (
        this.$route.query.flowType &&
        this.$route.query.flowType == "invoice_purchase_order"
      ) {
        options = conditionTypesInvoice;
      }

      if (!this.isFeatureAllowed("legalEntity")) {
        return options.filter((opt) => opt.value !== "legalEntity");
      } else {
        return options;
      }
    },
  },
  created() {
    const {
      amount,
      category,
      user,
      department,
      legalEntity,
      supplier,
      unlistedSupplier,
      amountDeviation,
      deviation,
      project,
      isProject,
      requestType,
    } = this.workflow.conditions;
    const absoluteDeviation = this.selectedOption == "amountDeviation";
    const object = absoluteDeviation ? amountDeviation : deviation;

    if (amount) this.amount = Object.assign({}, amount);
    if (category) this.category = category;
    if (project) this.project = project;
    if (isProject) this.isProject = isProject;
    if (user) this.user = user;
    if (department) this.department = department;
    if (amountDeviation || deviation)
      this.deviation = Object.assign({ absoluteDeviation }, object);
    if (legalEntity) this.legalEntity = legalEntity;
    if (supplier) this.supplier = supplier;
    if (unlistedSupplier) this.unlistedSupplier = unlistedSupplier;
    if (requestType) this.requestType = requestType;
  },
  methods: {
    requestTypeOptions,
    onSave() {
      if (this.isValidSubmission) {
        this.saveValue(
          "conditions",
          this.selectedCategory,
          this.conditionValue
        );
        this.closeModal();
      } else {
        this.showNotification(this.$t("errors.genericError"), "error");
      }
    },
    onAttributeChange(event) {
      const { value } = event.target;
      this.amount.value = value;
    },
    onAttributeChangeDeviation(event) {
      const { value } = event.target;
      this.deviation.value = value;
    },
    validateBetweenValues(minValue, maxValue) {
      return (
        !isEmptyString(minValue) &&
        !isEmptyString(maxValue) &&
        parseFloat(minValue) < parseFloat(maxValue)
      );
    },
  },
};
</script>
